import { Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { LinkComponent } from 'components/v2/Link'
import { Accordion } from 'components/v2/Accordion'

const FrequentlyQuestions = () => (
  <section id='frequently-questions'>
    <Row flexWrap='wrap' minWidth='100%' background='#F9FAFD'>
      <Row maxWidth='1200px' margin='0 auto' py='5' px='3' flexWrap='wrap'>
        <Row flexWrap='wrap' minWidth='100%' mb='40px'>
          <Text mb='3' fontSize={['24px', '32px']} fontWeight='600' color='#232936'>
            Possui dúvidas?
          </Text>
          <Text color='#232936' variant='regular'>
            Para que você possa iniciar sua jornada terapêutica com segurança e tranquilidade,
            confira a resposta dos nossos especialistas para algumas das perguntas mais frequentes
            sobre psicoterapia online - uma solução de democratização do cuidado com a saúde mental
            à população que vive em regiões com acesso limitado a serviços psicológicos, brasileiros
            que moram no exterior ou para aqueles que buscam mais comodidade para cuidar da saúde
            mental. <br /> <br />
            *A terapia online foi regulamentada no Brasil em 12/11/2018, através da Resolução CFP
            011/2018.
          </Text>
        </Row>
        <Row flexWrap='wrap' minWidth='100%' mb='5' className='frequently-asked-questions'>
          <Accordion label='Quanto custa a terapia online?' mb='2'>
            <Text color='#7f8c8d' variant='small'>
              Além da conveniência, a terapia online também torna o encontro com seu psicólogo mais
              acessível. Na Vittude é possível agendar sessões de terapia com valores a partir de
              R$50,00 por sessão. E, se você for um colaborador de empresa parceira, ainda é
              possível ter parte desses valores subsidiados pelo seu empregador.
              <br /> <br /> Menor preço não é significado de qualidade inferior. Aqui temos
              profissionais super experientes, que atuam com valores menores em função do local da
              sua residência. Longe dos grandes centros, diversos psicólogos altamente capacitados
              conseguem ofertar seus serviços com valores menores, proporcionando economia ao
              paciente.
            </Text>
          </Accordion>
          <Accordion label='Como funciona a terapia online?' mb='2'>
            <Text color='#7f8c8d' variant='small'>
              Terapia online é a modalidade de atendimento psicológico que mais cresce no Brasil.
              Sua prática foi autorizada pelo Conselho Federal de Psicologia em 2018, sem restrições
              com relação ao limite de sessões. <br />
              <br />
              Terapia online é bastante acessível e conveniente. Você tem a possibilidade de
              conversar com seu psicólogo do conforto da sua própria casa. Na maioria das vezes você
              pode agendar suas sessões de terapia para os horários mais convenientes para você,
              como finais de semana e madrugadas.
              <br />
              <br />
              Nela o psicólogo realiza consultas, aconselhamento e orientação psicológica por meio
              da internet. O atendimento acontece em tempo real em conversas por videoconferência,
              dentro de um ambiente seguro e sigiloso. Na terapia online você conversa com seu
              psicólogo usando seu computador ou smartphone.
            </Text>
          </Accordion>
          <Accordion label='E se eu não gostar do meu psicólogo?' mb='2'>
            <Text color='#7f8c8d' variant='small'>
              Para o sucesso de uma sessão de terapia é importante que haja sintonia entre você e
              seu psicólogo. Assim como em qualquer relação humana, a empatia é um fator
              fundamental. É possível que, mesmo escolhendo um profissional muito experiente, você
              não se identifique com a metodologia ou mesmo com a forma como a sessão ocorre. <br />{' '}
              <br />
              Saiba que a falta de sintonia não é culpa sua e nem do psicólogo. O importante é que
              você não desista da terapia. Se você não gostar do profissional escolhido na primeira
              vez, experimente um segundo. Você pode também utilizar nossa ferramenta de match,
              desenvolvida por profissionais especializados em inteligência artificial ou pedir
              ajuda para nosso time, no chat da plataforma. <br /> <br />
              Lembre-se: não desista da terapia se não curtir o psicólogo. Escolha um novo psicólogo
              e tente mais uma vez.
            </Text>
          </Accordion>
          <Accordion label='Vocês aceitam plano de saúde?' mb='2'>
            <Text color='#7f8c8d' variant='small'>
              Todos os psicólogos da Vittude emitem recibos que podem ser utilizados no processo de
              reembolso junto à sua operadora de saúde. Sempre que fizer sua sessão, solicite seu
              recibo. Se precisar de ajuda para receber o reembolso, entre em contato com a sua
              operadora ou converse com nosso time de felicidade do cliente. <br />
              <br />
              Nossos colaboradores são treinados para cuidar com carinho e muita atenção de todos os
              nossos clientes.
            </Text>
          </Accordion>
        </Row>
        <Row flexWrap='wrap' minWidth='100%' justifyContent='flex-start'>
          <Text width='100%' color='#232936' fontSize='18px' fontWeight='600' mb='2'>
            Não encontrou o que procurava?
          </Text>
          <LinkComponent
            href='/faq'
            label='Veja mais respostas'
            variant='medium'
            icon={true}
            textDecoration='none'
          />
        </Row>
      </Row>
    </Row>
  </section>
)

export default FrequentlyQuestions
