import { Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { Button } from 'components/v2/Button'
import { StyledColumn, StyledImage } from './styles'
import Toast from './Toast'
import { useEffect, useState } from 'react'
import { checkIsMobile } from 'utils/helpers'

const FirstFold = ({ handleModal }) => {
  const [showToast, setShowToast] = useState(true)
  const isMobile = checkIsMobile(993)

  const closeToast = () => {
    localStorage.setItem('showFirstFoldToast', false)
    setShowToast(false)
  }

  useEffect(() => {
    const showFirstFoldToast = localStorage.getItem('showFirstFoldToast')

    if (showFirstFoldToast === null) {
      localStorage.setItem('showFirstFoldToast', true)
    }

    if (showFirstFoldToast === 'false') {
      setShowToast(false)
    }
  }, [])

  return (
    <Row
      background='#FF7708'
      height={['calc(100vh - 60px)', 'calc(100vh - 60px)', 'calc(100vh - 86px)']}
      justifyContent='center'
      position='relative'
      overflow='hidden'
      p='16px'
    >
      <Row px='20px' maxWidth='1200px' width='100%' alignItems='center' zIndex={2}>
        <StyledColumn justifyContent='flex-start' sm='12' md='6'>
          <Text color='white' mb='1'>
            TERAPIA ONLINE
          </Text>
          <Text fontWeight='600' fontSize='40px' color='white' mb='24px'>
            Seu psicólogo a qualquer hora, em qualquer lugar.
          </Text>
          <Button
            bg='white'
            color='primary'
            height='56px'
            fontSize='14px'
            fontWeight='600'
            lineHeight='19px'
            variant='primary-refit'
            maxWidth='200px'
            onClick={() => handleModal()}
          >
            Agendar consulta
          </Button>
        </StyledColumn>
      </Row>

      <StyledImage src='/static/images/home-hero.png' alt='Boneca sentada na lua' />

      {!isMobile && showToast && <Toast handleModal={handleModal} closeToast={closeToast} />}
    </Row>
  )
}

export default FirstFold
